<template>
	<div class="newsList" >
		<img :src="proxy.$axios.imgUrl + topImg" class="topImg" />

		<div class="news-list" ref="container" @scroll="handleScroll">
			<div class="list-item jucenter" @click="jump(item.id)" v-for="(item,index) in list" :key="index">
				<div class="item-left">
					<div>{{item.date}}</div>
					<div class="division"></div>
					<div>{{item.year}}</div>
				</div>
				<img :src="proxy.$axios.imgUrl + item.images" class="item-center" />
				<div class="item-right flex">
					<div class="title">
						{{item.title}}
					</div>
					<div class="subhead">
						{{item.synopsis}}
					</div>
					<div class="more flex">
						<img src="../../assets/image/ic_morejt.png" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import {
		onMounted,
		getCurrentInstance,
		ref
	} from 'vue';
	import {
		useRouter
	} from 'vue-router'
	import EventBus from "../../assets/common/event-bus.js"

	const router = useRouter()

	const {
		proxy
	} = getCurrentInstance()

	onMounted(() => {
		EventBus.emit("mgheadr", 4);
		getList()
		getTopimg()
	})

	function jump(id) {
		router.push({
			path: '/newsdetail',
			query: {
				id
			}
		})
	}

	const list = ref([])

	const page = ref(1)

	async function getList() {
		let res = await proxy.$axios.get('/api/index/getNewsData', {
			page: page.value
		})
		if (Array.isArray(res.data) && res.data.length) {
			list.value = [...list.value, ...res.data]
			page.value++
		}
	}
	
	const topImg = ref('')
	
	async function getTopimg() {
		let res = await proxy.$axios.get('/api/index/getTopImg', {
			type: 'news'
		})
		topImg.value = res.data.top_img
	}
</script>

<style scoped>
	.topImg {
		margin-top: 0.05rem;
		width: 100%;
		height: auto;
	}

	.news-list {
		padding-bottom: 0.34rem;
	}

	.news-list .list-item {
		margin-top: 0.25rem;
	}


	.news-list .list-item .item-left>div:first-child {
		font-weight: 500;
		font-size: 0.1rem;
		color: #999999;
		line-height: 0.11rem;
	}

	.news-list .list-item .item-left .division {
		width: 0.11rem;
		height: 0.02rem;
		background: #E6E6E6;
		margin: 0.04rem 0;
	}

	.news-list .list-item .item-left>div:last-child {
		font-weight: 500;
		font-size: 0.06rem;
		color: #999999;
		line-height: 0.06rem;
	}

	.news-list .list-item .item-center {
		width: 1.48rem;
		height: 0.98rem;
		display: block;
		margin: 0 0.13rem 0 0.2rem;
	}

	.news-list .list-item .item-right {
		flex-flow: column;
		width: 2.6rem;
		height: 100%;

	}

	.news-list .list-item .item-right .title {
		font-weight: 500;
		font-size: 0.09rem;
		line-height: 0.12rem;
		text-overflow: ellipsis;
		overflow: hidden;
		word-break: break-all;
		white-space: nowrap;
	}

	.news-list .list-item .item-right .subhead {
		font-weight: 400;
		font-size: 0.06rem;
		color: #666666;
		line-height: 0.1rem;
		margin-top: 0.11rem;
		word-break: break-all;
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	.news-list .list-item .item-right .more {
		width: 0.2rem;
		height: auto;
		margin-top: 0.2rem;
	}

	.news-list .list-item .item-right .more>img {
		display: block;
		width: 100%;
		height: auto;
	}

	.news-list .list-item:hover {
		color: #008AD2;
	}

	.news-list .list-item:hover .item-right .subhead {
		color: #008AD2;
	}
</style>